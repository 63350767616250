<template>
    <div>
		<v-alert v-for="(message, index) in messages" :key="index" :value="true" :color="calculate_class_name(message.passed)" icon="info" outline>
      <b v-html="message.text"></b><br>
      <i v-html="message.hint"></i>
		</v-alert>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "GeneralGroupAttributesTests",
  data() {
    return {
      
      messages: [
				{
					uid: 'test_1',
					text: 'email_sender_address is set',
					hint: 'This is required to be able send out email messages from the system.<br/>',
					passed: null,
				},
				{
					uid: 'test_2',
					text: 'email_sender_name is set',
					hint: 'This is required to be able send out email messages from the system',
					passed: null,
				},
				{
					uid: 'test_3',
					text: 'sms_sender_name is set',
					hint: 'This is required to be able send out SMS messages from the system',
					passed: null,
				},
				{
					uid: 'test_4',
					text: 'sms_encoding is set',
					hint: 'This is required to be able send out SMS messages from the system',
					passed: null,
				},
				{
					uid: 'test_5',
					text: 'group_image is set',
					hint: 'This will be used in group list menu and as fallback image on PUSH notifications',
					passed: null,
				},
      ],
    };
  },
  computed: {
    currentGroupId() {
      return this.$route.params.group_id;
    },
  },
  created() {
      axios.get('/groups/' + this.currentGroupId + '/?with_attributes=1')
        .then((response) => {
          let group = response.data.data;
          let test_1 = this.find_test_by_uid('test_1');
          if(test_1)
          {
          	test_1.passed = (/\S+@\S+\.\S+/.test(group.attributes.email_sender_address));
          }
          let test_2 = this.find_test_by_uid('test_2');
          if(test_2)
          {
          	test_2.passed = (group.attributes.email_sender_name && group.attributes.email_sender_name.length > 0) ? true : false;
          }
          let test_3 = this.find_test_by_uid('test_3');
          if(test_3)
          {
          	test_3.passed = (group.attributes.sms_sender_name && group.attributes.sms_sender_name.length > 0 && group.attributes.sms_sender_name.length <= 11) ? true : false;
          }
          let test_4 = this.find_test_by_uid('test_4');
          if(test_4)
          {
          	test_4.passed = (group.attributes.sms_encoding == 'UTF8') ? true : false;
          }
          let test_5 = this.find_test_by_uid('test_5');
          if(test_5)
          {
          	axios.get('/media/' + group.attributes.group_image).then( (r) => {
          		test_5.passed = (r.data.data.presets.thumb) ? true : false;
          	})
		        .catch((error) => {
		        	test_5.passed = false;
		        })
          }
        })
        .catch((error) => {
        	// console.log(error);
        })
  },
	methods: {
		calculate_class_name(status) {
			if(status == null)
			{
				return 'info';
			}
			return (status) ? 'success' : 'error'
		},
		find_test_by_uid(uid) {
			return this.messages.find( (i) => {
      	return i.uid == uid;
      })
		},
	},
};
</script>

<template>
    <div>
		<v-alert v-for="(message, index) in messages" :key="index" :value="true" :color="calculate_class_name(message.passed)" icon="info" outline>
      <b v-html="message.text"></b><br>
      <i v-html="message.hint"></i>
		</v-alert>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "HorizonTests",
  data() {
    return {
      
      messages: [
      	{
      		uid: 'horizon_1',
      		text: 'Laravel horizon is running',
      		hint: 'This is required to be able process queues',
      		passed: null,
      	},
      ],
    };
  },
  computed: {
    currentGroupId() {
      return this.$route.params.group_id;
    },
  },
  created() {
      axios.get('/horizon/stats', [])
        .then((response) => {
          // 
          let horizon_1 = this.find_test_by_uid('horizon_1');
          if(horizon_1)
          {
            horizon_1.passed = (response.data.status == 'running') ? true: false;
          }

        })
        .catch((error) => {

        })
  },
  methods: {
    calculate_class_name(status) {
      if(status == null)
      {
        return 'info';
      }
      return (status) ? 'success' : 'error'
    },
    find_test_by_uid(uid) {
      return this.messages.find( (i) => {
        return i.uid == uid;
      })
    },
  },
};
</script>

<template lang="en">
  <v-container class="general">
    <general_group_attribute_tests />
    <horizon_tests />
  </v-container>
</template>

<script>
import general_group_attribute_tests from "@/components/Tests/GeneralGroupAttributesTests.vue";
import horizon_tests from "@/components/Tests/HorizonTests.vue";

export default {
  components: {
    general_group_attribute_tests: general_group_attribute_tests,
    horizon_tests: horizon_tests,
  },
  methods: {
    // ...
  },
};
</script>
